<template>
  <div class="shop">
    <el-carousel height="500px">
      <el-carousel-item v-for="item in 1"
                        :key="item">
        <img src="../assets/image/shop-banner.png"
             alt="" />
        <div class="banner-container">
          <p class="big">可以学知识</p>
          <p class="sub">的玩教具</p>
          <div class="btn">
            <img src="../assets/image/icon-books.png"
                 alt=""
                 class="icon" />气象教育知识大课堂
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="main">

      <img src="../assets/goin4.png"
           style="width: 400px;"
           alt="">
      <p style="color: rgba(255, 0, 0, 0.692);font-size: 28px;width: 1080px;margin: 20px auto;border: 10px solid rgba(127, 255, 212, 0.452);padding: 20px 5px; line-height: 60px;">为了方便大家选购科学设备、校园课程材料等，科学玩家的商城小程序今天正式上线啦。现在商城任意购物，还将额外赠送积分，每1积分后续购物可抵扣1元现金。</p>

      <p style="color: rgba(255, 0, 0, 0.692);font-size: 28px;width: 1080px;margin: 20px auto;border: 10px solid rgba(127, 255, 212, 0.452);padding: 20px 5px; line-height: 60px;">与此同时，为了促进校园科学活动开展，在2024年9月11日前选购CF8型气象站的校园用户，将免费获赠全部在线气象科学课程（超过45节PPT课件，价值约1万元）。</p>
      <img src="../assets/gis-personal/bg.png"  alt="">
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tabIndex: 0,
      tabList: ["气象课程", "观测仪器", "科普宣传", "实验套装", "校园建设"],
    };
  },
  methods: {
    show () {
      this.$router.push("/showShop");
    },
  },
};
</script>

<style lang="less">
.shop {
  .el-carousel__indicators--horizontal {
    bottom: 100px;
  }
  > .main {
    text-align: center;
    width: 1280px;
    // height: 1059px;
    background: #ffffff;
    box-shadow: 0px 5px 10px 0px rgba(204, 204, 204, 0.3);
    border-radius: 10px;
    margin: 0 auto;
    z-index: 2;
    position: relative;
    top: -100px;
    padding: 30px 53px;
    box-sizing: border-box;

    > .tab {
      text-align: center;
      display: flex;
      align-items: center;
      > span {
        flex: 1;
        padding: 8px 27px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #595959;
        cursor: pointer;
        &.active {
          background: linear-gradient(0deg, #d411ab, #bd0096);
          box-shadow: 0px 10px 20px 0px rgba(164, 1, 130, 0.3);
          border-radius: 20px;
          color: white;
        }
      }
    }

    > .content {
      box-sizing: border-box;
      display: flex;
      align-items: flex-start;
      align-content: flex-start;
      flex-wrap: wrap;
      > .item {
        width: 350px;
        height: 160px;
        float: left;
        margin: 40px 20px 0;
        background: #f6f6f6;
        border-radius: 10px;
        position: relative;

        > img {
          height: 100%;
          vertical-align: middle;
        }
        > div {
          display: inline-block;
          vertical-align: middle;
          margin-left: 10px;

          > span {
            display: inline-block;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #fffefe;
            padding: 2px 5px;
            background: linear-gradient(0deg, #fbd936 0%, #fe8b1b 100%);
            border-radius: 5px;
          }

          > p {
            margin: 0;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 600;
            color: #000000;
            line-height: 42px;
          }

          > ul {
            margin: 0;
            > li {
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 800;
              color: #737373;
              line-height: 20px;
            }
          }
        }

        &.shoufei::after {
          content: '';
          position: absolute;
          display: block;
          width: 76px;
          height: 76px;
          top: 0;
          right: 0;
          background-image: url('../assets/image/shoufei.png');
        }
        &.mianfei::after {
          content: '';
          position: absolute;
          display: block;
          width: 76px;
          height: 76px;
          top: 0;
          right: 0;
          background-image: url('../assets/image/mianfei.png');
        }
      }

      &::after {
        content: '';
        display: block;
        clear: both;
      }
    }
  }
}
</style>